export const CocktailData = [
  {
    name: 'Mai Tai',
    description:
      'Plantation 3 Star Rum, Plantation dark Rum, lime, Mathilde Orange Liqueur, and orgeat infused with thai candy. Refreshing, bright, sweet. We like to pair it with all meals, especially spicy ones.',
    price: {
      lunch: ['$10'],
      dinner: ['$10'],
    },
  },

  {
    name: 'Massamanhattan',
    description:
      'Four Roses Yellow Label, Pivon sweet vermouth, our home made simple syrup of palm sugar infused with massaman curry, dash Ango bitters, and thai cherry. Boozy, all spice, rich. We like to pair it with noodle dishes and Massaman curry.',
    price: {
      lunch: ['$12'],
      dinner: ['$12'],
    },
  },
  {
    name: 'Baramee Old Fashioned',
    description:
      'Plantation Xaymaca, Big O ginger reduction, and 2 dash Ango bitters. Smooth, slightly sweet and boozy. We like to pair it with noodle dishes.',
    price: {
      lunch: ['$10'],
      dinner: ['$10'],
    },
  },
  {
    name: 'Thai Trifecta',
    description:
      'Tequila, Pineapple and Lime Juice, Big O Ginger reduction, Ancho Reyes, and a hint of Basil. Tropical, sweet, refreshing. We like to pair with all dishes, especially when served spicy.',
    price: {
      lunch: ['$12'],
      dinner: ['$12'],
    },
  },
  {
    name: 'Tom kha',
    description:
      'Sochu, lime juice, and our own syrup infused with coconut milk, ginger, and lemongrass. Savory, perfectly paired with Tom Kha soup. We also like it with our fresh spring rolls.',
    price: {
      lunch: ['$10'],
      dinner: ['$10'],
    },
  },
  {
    name: 'Thai 75',
    description:
      'Citadelle Gin, lemon juice, elderflower, Topped with Brut. Effervescent, light, floral. This pairs well with all of our appetizers, but we drink it with everything.',
    price: {
      lunch: ['$10'],
      dinner: ['$10'],
    },
  },
  {
    name: 'Tonic',
    description:
      'Citadelle gin mixed with tonic that we infuse with lemongrass, cucumber and dill. Crisp, refreshing, light. This is great with appetizers as well as light meals.',
    price: {
      lunch: ['$9'],
      dinner: ['$9'],
    },
  },
  {
    name: 'Thai Iced Coffee',
    description:
      'Vodka, cold brew, burnt sugar, Midnight espresso liqueur. Sweet, caffeinated, rich. Pairs well with the Mango sticky rice after dinner, but we will drink this anytime.',
    price: {
      lunch: ['$10'],
      dinner: ['$10'],
    },
  },
  {
    name: 'Mango Margarita',
    description:
      'Tequila, Mezcal, mango juice, and Orange Liqueur infused with Curry. Refreshing, sweet, smokey. We like to pair with all dishes, especially when served spicy.',
    price: {
      lunch: ['$10'],
      dinner: ['$10'],
    },
  },
]
